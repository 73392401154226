<template>
  <div class="login-container">
    <h2 class="title"><i class="icon-user"></i>{{ $t('user.registerTitle') }}</h2>
    <form @submit.prevent>
      <div class="form-item">
        <input type="text" v-model="user.email" :placeholder="$t('user.email')" autocomplete="off" />
        <transition name="fade-in">
          <p class="error-tips" v-if="errorTips.email">
            {{ errorTips.email }}
          </p>
        </transition>
      </div>
      <div class="form-item">
        <input type="password" v-model="user.password1" autocomplete="off" :placeholder="$t('user.password')" />
        <transition name="fade-in">
          <p class="error-tips" v-if="errorTips.password1">
            {{ errorTips.password1 }}
          </p>
        </transition>
      </div>
      <div class="form-item">
        <input type="password" v-model="user.password2" autocomplete="off" :placeholder="$t('user.confirmPwd')" />
        <transition name="fade-in">
          <p class="error-tips" v-if="errorTips.password2">
            {{ errorTips.password2 }}
          </p>
        </transition>
      </div>
      <!-- <div class="form-item">
        <input type="text" v-model="user.phone" :placeholder="$t('user.phone2')" autocomplete="off" />
      </div> -->
      <div class="form-item">
        <ali-nc @callback="handleAliNcCb" ref="aliNc" />
        <transition name="fade-in">
          <p class="error-tips" v-if="errorTips.token">
            {{ errorTips.token }}
          </p>
        </transition>
      </div>
      <div class="form-item">
        <button class="button long" @click="submit">{{ $t('user.registerSubmitText') }}</button>
      </div>
      <div class="form-item" v-if="locale === 'zh_CN'">
        <a-checkbox @change="onChange"> </a-checkbox>
        <router-link to="/agreement" target="_blank" class="agreement"> 《用户服务协议》 </router-link>
        <transition name="fade-in">
          <p class="error-tips" v-if="errorTips.agreement">
            {{ errorTips.agreement }}
          </p>
        </transition>
      </div>
      <div class="form-item" style="margin-top: 80px">
        <p class="text-center">
          {{ $t('user.haveAnAccount') }}<router-link :to="{ name: 'login' }" class="handle-btn primary">{{ $t('user.loginNow') }}</router-link>
        </p>
      </div>
    </form>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import schema from 'async-validator';
import aliNc from '../components/aliNc';
import {register} from '../services/user';

const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export default {
    name: 'login',
    components: {
        aliNc,
    },
    data() {
        const descriptor = {
            email: [
        {
          type: 'string',
          required: true,
          message: this.$t('user.enterEmail'),
        },
        {
          pattern: emailReg,
          message: this.$t('user.enterLegalEmail'),
        },
      ],
      password1: [
        {
          type: 'string',
          required: true,
          message: this.$t('user.enterPassword'),
        },
        {
          validator: (rule, value) => value.length >= 6,
          message: this.$t('user.enterLegalPassword'),
        },
      ],
      password2: {
        type: 'string',
        required: true,
        validator: (rule, value) => value.length >= 6 && value === this.user.password1,
        message: this.$t('user.confirmPassword'),
      },
      token: [
        {
          type: 'string',
          required: true,
          message: this.$t('user.confirmAliCode'),
        },
      ],
      agreement: [
        {
          type: 'string',
          required: true,
          validator: (rule, value) => this.user.checked == true,
          message: '请勾选同意协议',
        },
      ],
    };
    return {
      user: {
        email: '',
        password1: '',
        password2: '',
        // phone: '',
        sessionId: '',
        sig: '',
        token: '',
        scene: 'nc_login',
        checked: false,
      },
      validator: new schema(descriptor),
      errorTips: {
        email: '',
        password1: '',
        password2: '',
      },
    };
  },
  computed: {
    ...mapGetters(['locale']),
  },
  mounted() {
    if (this.locale !== 'zh_CN') this.user.checked = true;
    // this.$router.push({name: 'register', query: {lang: this.locale}})
    if (this.$route.query.email) {
      this.user.email = this.$route.query.email;
    }
  },
  methods: {
    onChange(e) {
      this.user.checked = e.target.checked;
    },
    handleAliNcCb(data) {
      this.user.sig = data.sig;
      this.user.sessionId = data.csessionid;
      this.user.token = data.token;
    },
    submit() {
      this.validator
        .validate(this.user)
        .then(async () => {
          // 注册前先请求拿一次鉴权
          await this.$store.dispatch('initAuth', true);
          register(this.user)
            .then(res => {
              if (res.code === 0) {
                this.$success({
                  title: this.$t('user.checkEmail'),
                });
              } else {
                const errorMsg = {
                    7: '邮箱格式不对',
                    8: '邮箱已注册',
                    9: '邮箱未激活',
                    10: '密码长度小于6位',
                    11: '两次密码不一致',
                    12: '手机号格式不对',
                    13: '人机验证不通过',
                };
                this.$message.error(errorMsg[res.code]);
                this.$refs.aliNc.reset();
              }
            })
            .catch(err => {
              this.$error({
                title: this.$t(`error['${err.response.data.errorKey}']`),
              });
              this.$refs.aliNc.reset();
            });
        })
        .catch(({ errors, fields }) => {
          let errorTips = {};
          errors.forEach(error => {
            if (!errorTips[error.field]) {
              errorTips[error.field] = error.message;
            }
          });
          this.errorTips = errorTips;
        });
    },
  },
};
</script>
<style lang="less" scoped>
@import '../assets/css/theme.less';
.login-container {
  width: 500px;
  margin: 60px auto;
  // border: 1px solid #f1f1f1;
  border-radius: 3px;
  h2 {
    font-size: 20px;
    padding: 20px 40px;
    text-align: center;
    i {
      margin-right: 10px;
    }
  }
}
a {
  &:hover {
    color: #fac92b !important;
  }
}
.form-item {
  padding: 0 40px;
  margin-bottom: 20px;
  position: relative;
  input {
    width: 100%;
    padding: 0 10px;
    background-color: #fff;
    height: 40px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    &:-webkit-autofill {
      box-shadow: 0 0 0px 1000px white inset !important;
    }
    &:focus {
      border-color: @primary-color;
    }
  }
  .error-tips {
    position: absolute;
    left: 40px;
    font-size: 12px;
    padding-top: 4px;
    color: @red-color;
  }
}
.handle-btn {
  margin-bottom: 10px;
  &:hover,
  &.primary {
    color: @blue-color;
  }
}
.long {
  width: 100%;
  margin: 0;
}
</style>
